var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tablePart"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"table",attrs:{"data":_vm.orderListData,"border":"","stripe":""},on:{"selection-change":_vm.handleSelectionChange}},[_vm._l((_vm.attributeData),function(ref,index){
var type = ref.type;
var label = ref.label;
var width = ref.width;
var prop = ref.prop;
return [(type == 'selection')?_c('el-table-column',{key:index,attrs:{"type":type}}):(label == '物流')?_c('el-table-column',{key:index,attrs:{"label":label,"min-width":width},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"Psize"},[_c('b',[_vm._v("收货人:")]),_vm._v(" "),_c('i',[_vm._v(_vm._s(row.consigneeName))])]),_c('span',{staticClass:"Psize"},[_c('b',[_vm._v("国家:")]),_vm._v(" "),_c('i',[_vm._v(_vm._s(row.consigneeCountryCode))])]),_c('br'),_c('span',{staticClass:"Psize"},[_c('b',[_vm._v("物流方式:")]),_vm._v(" "),_c('i',[_vm._v(_vm._s(_vm.expressName(row)))])]),_c('span',{staticClass:"Psize"},[_c('b',[_vm._v("运单号:")]),_vm._v(" "),_c('i',[_vm._v(_vm._s(row.expressWaybillCode))])])]}}],null,true)}):(prop == 'isCustomsDeclaredExported')?_c('el-table-column',{key:index,attrs:{"min-width":width,"label":label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.isCustomsDeclaredExported ? '已导出' : '未导出')+" ")]}}],null,true)}):(label == '物流到期时间')?_c('el-table-column',{key:index,attrs:{"label":label,"min-width":width},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(parseInt(scope.row.expressExpireTime) <= 3)?_c('div',[_vm._v(" "+_vm._s(scope.row.expressExpireTime)+" "),_c('el-tag',{staticClass:"tixing",attrs:{"type":"danger","size":"mini"}},[_vm._v("临近提醒")])],1):_c('div',[_vm._v(" "+_vm._s(scope.row.expressExpireTime)+" ")])]}}],null,true)}):(label == '订单状态')?_c('el-table-column',{key:index,attrs:{"min-width":width,"label":label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.ORDER_STATUS_LIST[row.orderStatus])+" ")]}}],null,true)}):(prop == 'userName')?_c('el-table-column',{key:index,attrs:{"min-width":width,"label":label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.userName)),(row.userStatus === 2)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("(已注销)")]):_vm._e()]}}],null,true)}):_c('el-table-column',{key:index,attrs:{"label":label,"min-width":width,"prop":prop}})]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }