<template>
  <div class="formPart">
    <el-form :model="query" inline size="mini">
      <el-form-item label="导出状态">
        <el-select v-model="query.isCustomsDeclaredExported" clearable @change="toQuery">
          <el-option label="未导出" value="0"></el-option>
          <el-option label="已导出" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="导入时间">
        <SaleDateTime :time.sync="time" @change="toQuery" />
      </el-form-item>
      <el-form-item label="订单号 ">
        <el-input v-model="query.orderCode" placeholder="搜索订单号" clearable @keyup.enter.native="toQuery"></el-input>
      </el-form-item>
      <el-form-item label="运单号">
        <el-input
          v-model="query.expressWaybillCode"
          placeholder="搜索运单号"
          clearable
          @keyup.enter.native="toQuery"
        ></el-input>
      </el-form-item>
      <el-form-item label="物流方式 ">
        <!-- <el-input
          v-model="query.orderCode"
          placeholder="搜索物流方式"
          clearable
          @keyup.enter.native="toQuery"
        ></el-input> -->
        <el-select v-model="query.expressCompanyId" clearable @change="toQuery">
          <el-option v-for="{ id, companyName } in expressList" :key="id" :label="companyName" :value="id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button class="el-icon-search" type="primary" @click="toQuery"></el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import SaleDateTime from '@/components/saleDateTime'
import { commonEheaderMixin } from '@/mixins'
import { list } from '@/api/fadexApi'
export default {
  mixins: [commonEheaderMixin],
  components: {
    SaleDateTime
  },
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      time: [],
      formField: [{ prop: 'importTime' }],
      expressList: []
    }
  },
  methods: {
    async getExpressList() {
      const page = {
        pageIndex: 1,
        pageSize: 0
      }
      const { detail } = await list({ page })
      this.expressList = detail
    }
  },
  created() {
    this.getExpressList()
  }
}
</script>

<style scoped lang="scss">
.formPart {
  margin: 20px 0;
  background-color: #f7f8fa;
  padding: 20px 20px 5px;
  .el-form-item {
    margin-right: 20px;
  }
}
</style>
