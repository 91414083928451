<template>
  <div class="app-container">
    <div v-scrollTop>
      <formPart
        v-p="['externaladmin:orderManage:order:customsDeclarationList:search']"
        :query="query"
        :sup_this="sup_this"
      ></formPart>
      <exportBtn
        v-p="['externaladmin:orderManage:order:customsDeclarationList:export']"
        ref="exportBtn"
        :sup_this="sup_this"
        :selectedData="selectionDataAllArr"
        :tableData="data"
        :page="page"
        :size="size"
        :total="total"
        :isPageChange="isPageChange"
        :setIsPageChangeFalse="setIsPageChangeFalse"
        :getAllData="getAllData"
        :selectionData="selectedData"
        :getElTableInstance="getElTableInstance"
        :getSelectionDataAllArr="getSelectionDataAllArr"
        @checkAllStatus="checkAllStatusChange"
      ></exportBtn>
      <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
      <tablePart
        ref="table"
        :orderListData="data"
        :loading="tableLoading"
        @updateSelectedData="updateSelectedData"
      ></tablePart>
      <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
    </div>
  </div>
</template>

<script>
import formPart from './module/customsModule/formPart'
import exportBtn from './module/customsModule/exportBtn'
import tablePart from './module/customsModule/tablePart'
import pagination from './module/customsModule/pagination'
import { initDataMixin } from '@/mixins'
import { getValueFromObj } from '@/utils'
import { initData } from '@/api/data'
export default {
  name: 'orderCustomsDeclarationList',
  mixins: [initDataMixin],
  components: {
    formPart,
    exportBtn,
    tablePart,
    pagination
  },
  data() {
    return {
      sup_this: this,
      url: '/externaladmin/orderService/externalOrder/customsDeclareList',
      method: 'post',
      query: {
        orderItems: [
          {
            asc: false,
            column: 'order_time'
          },
          {
            asc: false,
            column: 'id'
          }
        ],
        isReturnRelatedData: 1
      },

      // 全选必加
      selectionDataAllArr: []
    }
  },
  created() {},
  methods: {
    updateSelectedData(data) {
      this.selectedData = data
    },

    // 全选必加
    initCallBack(res, postData) {
      let initCallBack = getValueFromObj(this.$refs, '.exportBtn.$refs.checkAllData.initCallBack')
      if (initCallBack) initCallBack(res?.detail || [], postData.page.pageIndex)
    },
    checkAllStatusChange(status) {
      if (['indeterminate', 'all'].includes(status)) {
        this.selectionDataAllArr = [true]
      } else {
        this.selectionDataAllArr = []
      }
      // console.log(this.selectionDataAllArr)
    },
    async getSelectionDataAllArr() {
      let tempArr = await this.$refs.exportBtn?.$refs?.checkAllData?.getSelectionDataAllArr()
      this.selectionDataAllArr = tempArr
      // console.log(tempArr)
      return tempArr
    },
    async getAllData() {
      let res = await awaitResolve(
        initData(this.url, this.method, {
          ...this.params,
          page: {
            pageIndex: 1,
            pageSize: 0
          }
        })
      )
      res = res?.detail
      if (!res) this.$message.warning('获取数据失败，请重试')
      return res
    },
    getElTableInstance() {
      return getValueFromObj(this.$refs, 'table.$refs.table') || {}
    }
  }
}
</script>

<style lang="scss" scoped></style>
