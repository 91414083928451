<template>
  <div class="exportBtn flex-middle">
    <checkAllData ref="checkAllData" class="ml20" v-bind="$attrs" v-on="$listeners"></checkAllData>
    <loadingBtn
      type="primary"
      size="mini"
      class="btn"
      @click="exportHandler"
      v-p="['externaladmin:orderManage:order:customsDeclarationList:export']"
      >导出所选</loadingBtn
    >
  </div>
</template>

<script>
import { exportForCustoms } from '@/api/order'
import { ORDER_EXPORTED } from '@/utils/constant'
import saveAs from 'jszip/vendor/FileSaver.js'
import checkAllData from '@/views/components/checkAllData3'

export default {
  components: {
    checkAllData
  },
  props: {
    selectedData: {
      type: Array,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    },
    getSelectionDataAllArr: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async exportHandler() {
      if (!(await this.getSelectionDataAllArr())) return
      if (!this.selectedData.length) {
        return this.$message.warning('请先选择数据再进行此操作！')
      }
      try {
        const idList = this.selectedData.map(({ id }) => id)
        const res = await exportForCustoms({
          idList,
          isCustomsDeclaredExported: ORDER_EXPORTED
        })
        // console.log('res', res)
        // return
        saveAs(res, '报关订单')
        this.$message.success('导出成功')
        this.sup_this && this.sup_this.init()
      } catch (err) {}
    }
  }
}
</script>

<style scoped lang="scss">
.exportBtn {
  height: 50px;
  width: 100%;
  border: 1px solid rgba($color: #000000, $alpha: 0.3);
  border-radius: 5px;
  margin: 20px 0;
  position: relative;
  .btn {
    position: absolute;
    right: 20px;
  }
}
</style>
